//import axios from 'axios';
import { useState, useEffect, useContext, Fragment } from "react";

//from redux-------------------------------------
import { comunicationInfoActions } from "../../store/comunication-slice";

//loader Imports--------------
import { SyncLoader } from "react-spinners";

import DashboardContainer from "../UI/DashboardContainer";
import { variables } from "../../Variables";
import CreateEmailSteps from "./createEmailSteps/CreateEmailSteps";
import CreateEmailStep_1 from "./createEmailSteps/CreateEmailStep_1";
import CreateEmailStep_2 from "./createEmailSteps/CreateEmailStep_2";
import CreateEmailStep_3 from "./createEmailSteps/CreateEmailStep_3";

import CreateInvoiceSteps from "../adminComponents/invoicingComponents/invoiceSteps/CreateInvoiceSteps";
import CreateInvoiceStep_1 from "../adminComponents/invoicingComponents/invoiceSteps/CreateInvoiceStep_1";




import { SocketContext } from "../../utils/socket-client";
import { useDispatch, useSelector } from "react-redux";
import {
  updateMessagesList,
  updateNotificationsTypeList,
} from "../../store/notifications-slice";


import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import CreateIcon from "@mui/icons-material/Create";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import Avatar from "@mui/material/Avatar";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import MarkunreadIcon from '@mui/icons-material/Markunread';

import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";

import ContactMailOutlinedIcon from "@mui/icons-material/ContactMailOutlined";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../UI/Theme";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import AddCardIcon from "@mui/icons-material/AddCard";
import Typography from "@mui/material/Typography";
import Draggable from "react-draggable";
//import Paper from "@mui/material/Paper";

//import Box from "@mui/material/Box";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const NewEmail = (props) => {
  //SOCKET Notifications Variables--------------------------------------------------------------
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user);
  const currentOrderSelected = useSelector(
    (state) => state.orders.selectedOrder
  );
  const currentPaymentPlanInfo = useSelector((state) => state.paymentPlan);
  const messageListInitial = useSelector(
    (state) => state.notifications.messages
  );
  const notificationsListInitial = useSelector(
    (state) => state.notifications.notificationsType
  );
  const [messagesList, setMessagesList] = useState(messageListInitial);
  const [notificationsList, setNotificationsList] = useState(
    notificationsListInitial
  );

  //const [value, setValue] = useState(0);

  const [usersTitleList, setUsersTitleList] = useState([]);

  const [socket, setSocket] = useState(null);
  const mySocket = useContext(SocketContext);

  const {
    paymentTypeList,
    isNewComunication,
    isNewPaymentPlan,
    newPaymentPlan,
    isLoading1_allPayOnOrder,
    isLoading1_PayPlanOnOrder,
    isLoading1_PayplanOnOrderHandler,
    invoiceRecorded,
    comunicationSent,
  } = props;
  const [showCreateEmailGeneral, setShowCreateEmailGeneral] = useState(false);
  const [showCreateInvoice, setShowCreateInvoice] = useState(false);

  const [showPplanSigned, setShowPplanSigned] = useState(false);
  const [showPplanSet, setShowPplanSet] = useState(false);
  const [showPplanSignedDetails, setShowPplanSignedDetails] = useState(false);

  const selectedOrder = useSelector((state) => state.orders.selectedOrder);

  //const matches = useMediaQuery(theme.breakpoints.down("lg"));

  //Show the modal with form to submit new payment in-----------
  //const showAddPaymentModalHandler = (event, action) => {
  const showComunicationModalHandler = (event, action) => {

    if (event) {
      event.preventDefault();
    }
    if (action === 1) {
      setShowCreateEmailGeneral((prev) => !showCreateEmailGeneral);
    } else if (action === 2) {
      setShowPplanSet((prev) => !showPplanSet);
    } else if (action === 3) {
      setShowPplanSigned((prev) => !showPplanSigned);
    } else if (action === 4) {
      setShowCreateInvoice((prev) => !showCreateInvoice);
    } else {
      setShowPplanSignedDetails((prev) => !showPplanSignedDetails);
    }
  };

  //CODE FOR THE SOCKET-------------------------------

  useEffect(() => {
    //setSocket(io("http://localhost:5002"));
    setSocket(mySocket);
    //console.log("connecting...");
  }, []);

  //listening to messages
  useEffect(() => {
    socket?.on("getText", (data) => {
      setMessagesList((prev) => [...prev, data.text]);
      //console.log("receiving a message object", data);
      //console.log("receiving a message", data.text);
    });
  }, [socket]);

  //listening to notifications
  useEffect(() => {
    socket?.on("getNotification", (data) => {
      setNotificationsList((prev) => [...prev, data]);
      //console.log("receiving a notification object for Comunication...", data);
      //console.log("receiving a notification", data.type);
    });
  }, [socket]);

  //getting all notifications from the socket to update the redux state------
  useEffect(() => {
    dispatch(
      updateMessagesList({
        messagesList,
      })
    );
  }, [messagesList]);

  //getting all notifications from the socket to update the redux state------
  useEffect(() => {
    dispatch(
      updateNotificationsTypeList({
        notificationsList,
      })
    );
  }, [notificationsList]);

  //CODE FOR THE SOCKET END---------------------------

  //Reseting the slice for paymentsIn redux
  useEffect(() => {
    //Reset The Payment In Slice to initial values function to be used after payment is recorded -----
    dispatch(
        comunicationInfoActions.resetComunicationCreateEmail({
          comunicationEmailDate: new Date().toISOString(),
        //paymentDate: new Date(date.setUTCHours(0, 0, 0, 0)).toISOString(),
        //paymentDate: new Date().toISOString(),
        //paymentDate: new Date().toISOString(),
      })
    );
    //END Reset The Payment In Slice to initial values function to be used after payment is recorded -----

    //Reset The Payment Plan Slice to initial values function to be used after payment is recorded -----
    // dispatch(
    //   paymentPlanActions.resetPaymentPlan({
    //     paymentPlanSignedDate: new Date().toISOString(),
    //   })
    // );
    //END Reset The Payment In Slice to initial values function to be used after payment is recorded -----
  }, []);

  //Steps for creating a new Payment-------------------------------------
  const steps = [
    {
      label: "Email Details",
      info: (
        <CreateEmailStep_1
          usersTitleList={usersTitleList}
          paymentTypeList={paymentTypeList}
        />
      ),
      icon: <MailOutlineIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
      iconCompleted: (
        <MailOutlineIcon sx={{ color: "#7A1125", fontSize: 30 }} />
      ),
      id: 0,
    },
    {
      label: "Summary",
      info: <CreateEmailStep_2 />,
      //info: "Payment Summary component...",
      icon: <ReceiptOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
      iconCompleted: (
        <ReceiptOutlinedIcon sx={{ color: "#7A1125", fontSize: 30 }} />
      ),
      id: 1,
    },
    {
      label: "Confirmation",
      info: (
        <CreateEmailStep_3
          socket={socket}
          userInfo={userInfo}
          showComunicationModalHandler={showComunicationModalHandler}
          isNewComunication={isNewComunication}
        />
      ),
      //info: "Payment comfirmation component...",
      icon: <ForwardToInboxIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
      iconCompleted: (
        <ForwardToInboxIcon sx={{ color: "#7A1125", fontSize: 30 }} />
      ),
      id: 2,
    },
  ];




    //Steps for creating a new Payment-------------------------------------
    const stepsInvoice = [
      {
        label: "Invoice Details",
        info: (
          <CreateInvoiceStep_1
            usersTitleList={usersTitleList}
            paymentTypeList={paymentTypeList}
          />
        ),
        icon: <MailOutlineIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
        iconCompleted: (
          <MailOutlineIcon sx={{ color: "#7A1125", fontSize: 30 }} />
        ),
        id: 0,
      },
      {
        label: "Summary",
        info: <CreateEmailStep_2 />,
        //info: "Payment Summary component...",
        icon: <ReceiptOutlinedIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
        iconCompleted: (
          <ReceiptOutlinedIcon sx={{ color: "#7A1125", fontSize: 30 }} />
        ),
        id: 1,
      },
      {
        label: "Confirmation",
        info: (
          <CreateEmailStep_3
            socket={socket}
            userInfo={userInfo}
            showComunicationModalHandler={showComunicationModalHandler}
            isNewComunication={isNewComunication}
          />
        ),
        //info: "Payment comfirmation component...",
        icon: <ForwardToInboxIcon sx={{ color: "#e7dddd", fontSize: 30 }} />,
        iconCompleted: (
          <ForwardToInboxIcon sx={{ color: "#7A1125", fontSize: 30 }} />
        ),
        id: 2,
      },
    ];



  return (
    <Fragment>
      <div>
        <Button
          onClick={(event) => {
            event.preventDefault();
            showComunicationModalHandler(event, 1);
          }}
          sx={{ color: "#7A1125", textTransform: "capitalize" }}
          //disabled={!selectedOrder.length ? true : false}
          disabled={!invoiceRecorded ? true : false}
        >
          <MailOutlineIcon
            sx={{
              fontSize: 20,
              //color: !selectedOrder.length ? "#ccc" : "#4E1721",
              color: !invoiceRecorded ? "#ccc" : "#4E1721",
              marginRight: 2,
              marginTop: 2,
            }}
          />
          <Typography
            align="center"
            //variant="body2"
            //color="text.secondary"
            sx={{ marginTop: 2, fontSize: 12 }}
          >
            New Email
          </Typography>
          {comunicationSent.length > 0 && (
            <CheckCircleOutlineIcon
              sx={{
                fontSize: 20,
                //color: !selectedOrder.length ? "#ccc" : "#4E1721",
                color: !selectedOrder.length
                  ? "#ccc"
                  : theme.palette.common.arcBlue,
                marginLeft: 2,
                marginTop: 2,
              }}
            />
          )}
        </Button>

        <Divider />

        <Button
          onClick={(event) => {
            event.preventDefault();
            showComunicationModalHandler(event, 4);
          }}
          sx={{ color: "#7A1125", textTransform: "capitalize" }}
          //disabled={!selectedOrder.length ? true : false}
          disabled={!invoiceRecorded ? true : false}
        >
          <MailOutlineIcon
            sx={{
              fontSize: 20,
              //color: !selectedOrder.length ? "#ccc" : "#4E1721",
              color: !invoiceRecorded ? "#ccc" : "#4E1721",
              marginRight: 2,
              marginTop: 2,
            }}
          />
          <Typography
            align="center"
            //variant="body2"
            //color="text.secondary"
            sx={{ marginTop: 2, fontSize: 12 }}
          >
            Get Invoice
          </Typography>
         
        </Button>

        {/* Modal for case: New email to client ---------------------- */}
        <Dialog
          open={showCreateEmailGeneral}
          //open={open}
          //onClose={handleClose}
          onClose={(event) => showComunicationModalHandler(event, 1)}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle>Create a New Email</DialogTitle>
          <DialogContent>
            {currentOrderSelected.length > 0 && (
              <DialogContentText sx={{ margin: 2 }}>
                for Order...{currentOrderSelected[0].CONFIRMATION_REFERENCE} --
                Client: {currentOrderSelected[0].COMPANY_NAME}
              </DialogContentText>
            )}

            <CreateEmailSteps steps={steps} />
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>

        {/* Modal for case: add payment plan signed ---------------------- */}
        <Dialog
          open={showPplanSigned}
          //open={open}
          //onClose={handleClose}
          onClose={(event) => showComunicationModalHandler(event, 3)}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle>Add Signed P. Plan</DialogTitle>
          <DialogContent>
            {currentOrderSelected.length > 0 && (
              <DialogContentText sx={{ margin: 2 }}>
                On Order...{currentOrderSelected[0].CONFIRMATION_REFERENCE} --
                Client: {currentOrderSelected[0].COMPANY_NAME}
              </DialogContentText>
            )}

            {/* <AddPplanSignedSteps
              steps={steps_signed}
              showAddPaymentModalHandler={showAddPaymentModalHandler}
            /> */}
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>

        {/* Modal for case: add payment plan SET ---------------------- */}
        <Dialog
          open={showPplanSet}
          //open={open}
          //onClose={handleClose}
          onClose={(event) => showComunicationModalHandler(event, 2)}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle>Add New P. Plan</DialogTitle>
          <DialogContent>
            {currentOrderSelected.length > 0 && (
              <DialogContentText sx={{ margin: 2 }}>
                On Order...{currentOrderSelected[0].CONFIRMATION_REFERENCE} --
                Client: {currentOrderSelected[0].COMPANY_NAME}
              </DialogContentText>
            )}

            {/* <AddPplanSignedSteps
              steps={steps_signed}
              showAddPaymentModalHandler={showAddPaymentModalHandler}
            /> */}
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>

        {/* Modal for case: Create New Invoice ---------------------- */}
        <Dialog
          open={showCreateInvoice}
          //open={open}
          //onClose={handleClose}
          onClose={(event) => showComunicationModalHandler(event, 4)}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle>Create a New Invoice</DialogTitle>
          <DialogContent>
            {currentOrderSelected.length > 0 && (
              <DialogContentText sx={{ margin: 2 }}>
                for Order...{currentOrderSelected[0].CONFIRMATION_REFERENCE} --
                Client: {currentOrderSelected[0].COMPANY_NAME}
              </DialogContentText>
            )}

            <CreateInvoiceSteps steps={stepsInvoice} />

          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>



        {/* Modal for case: Show payment plan signed Details ---------------------- */}
        <Dialog
          open={showPplanSignedDetails}
          //open={open}
          //onClose={handleClose}
          onClose={(event) => showComunicationModalHandler(event, 4)}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle>Signed P. Plan Details</DialogTitle>
          <DialogContent>
            {currentOrderSelected.length > 0 && (
              <Fragment>
                <DialogContentText sx={{ margin: 2 }}>
                  On Order...{currentOrderSelected[0].CONFIRMATION_REFERENCE} --
                  Client: {currentOrderSelected[0].COMPANY_NAME}
                </DialogContentText>

                <Typography
                  align="center"
                  //variant="body2"
                  //color="text.secondary"
                  sx={{ marginTop: 2 }}
                >
                  Payment Plan Signed. please see details as follows...
                </Typography>

                {/* <PaymentPlanInfo currentPaymentPlanInfo={currentPaymentPlanInfo}/> */}
              </Fragment>
            )}
            {/* <AddPplanSignedSteps steps={steps_signed} /> */}
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </div>
    </Fragment>
  );
};

export default NewEmail;

import {createSlice} from '@reduxjs/toolkit';

export const invoicingSlice = createSlice({
    name:"invoicing",
    initialState:{

        invoicingInfo:{    
            selectedOrder:[],
            //orderDate:"",
            //PaymentPlanRepaymentNumber:"",
            //PaymentPlanId:null,
            invoiceBookDate:"",
        },

        invoicesOnOrder:{
            invoice1:[],
            invoice2:[],
            invoice3:[],
        },
        // invoicesOnOrder_2:{
        //     invoice2:[],
        // },

        
        //invoiceBookId:null,
        
    },
    reducers:{

        updateInvoiceInfo: (state, action)=>{
            const {payload} = action; 
            state.invoicingInfo = {...state.invoicingInfo, ...payload };
        },
        updateInvoice: (state, action)=>{
            const {payload} = action; 
            state.invoicesOnOrder = {...state.invoicesOnOrder, ...payload };
        },
        // updateInvoice_2: (state, action)=>{
        //     const {payload} = action; 
        //     state.invoicesOnOrder_2 = {...state.invoicesOnOrder_2, ...payload };
        // },
        // updatePayPlanSigned: (state, action)=>{
        //     const {payload} = action 
        //     state.paymentPlanSignedDate = payload.paymentPlanSignedDate
        //     state.paymentPlanSignedId = payload.paymentPlanSignedId
        // },
        resetInvoice:(state, action)=>{

            const {payload} = action;

            state.invoicingInfo={
                selectedOrder:[],
                //orderDate:"",
                //PaymentPlanRepaymentNumber:0,
                //PaymentPlanId:null,
                invoiceBookDate: payload.invoiceBookDate
                
            };

            state.invoicesOnOrder={
                invoice1:[],
                invoice2:[],
                invoice3:[],
            };
            // state.invoicesOnOrder_2={
            //     invoice2:[],
            // };

            //state.invoicingInfo.invoiceBookDate= payload.invoiceBookDate;
            

            //state.paymentPlanSignedId = null;
            

        }


    }
})

export const invoicingActions = invoicingSlice.actions;

export default invoicingSlice;
import * as React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
//import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';
import Typography from '@mui/material/Typography';

import {useDispatch} from 'react-redux';
//import {commentsActions} from  "../../store/comments-slice";
import {orderActions} from  "../../store/order-slice";
import {paymentInActions} from  "../../store/payment-slice";
import {paymentPlanActions} from  "../../store/paymentPlan-slice";
import {invoicingActions} from  "../../store/invoicing-slice";
import {comunicationInfoActions} from "../../store/comunication-slice";



import { useSelector } from 'react-redux';


const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  // if (dataSet.hasOwnProperty('group')) {
  //   return (
  //     <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
  //       {dataSet.group}
  //     </ListSubheader>
  //   );
  // }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1]}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });

  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

// function random(length) {
//   const characters =
//     'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
//   let result = '';

//   for (let i = 0; i < length; i += 1) {
//     result += characters.charAt(Math.floor(Math.random() * characters.length));
//   }

//   return result;
// }

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

// const OPTIONS = Array.from(new Array(30000))
//   .map(() => random(10 + Math.ceil(Math.random() * 20)))
//   .sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));



// const OPTIONS = Array.from(new Array(30000))
//   .map(() => random(10 + Math.ceil(Math.random() * 20)))
//   .sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));

export default function Virtualize(props) {
  const dispatch = useDispatch();

  const {
    listFiltered,
    currentorderSelectedRefFinder,
  } = props;

  //const allOrders = useSelector(state =>state.orders.ordersList);
  const selectedOrder = useSelector(state=>state.orders.selectedOrder);

  const currentPaymentInfo = useSelector((state) => state.paymentIn.paymentInfo);
  
  

  //Temporary to be deleted -------------------------------------------------
   //console.log(listFiltered)
  // if(listFiltered.length > 0){
  //   console.log("this is the list from virtual component...",listFiltered)
  // }
  //END Temporary to be deleted -------------------------------------------------

  const OPTIONS_2 = listFiltered.map((option)=> option.CONFIRMATION_REFERENCE.toString())
  


  const handleChange = (event, value)=>{

    dispatch(paymentPlanActions.resetPaymentPlan({paymentPlanSignedDate: new Date().toISOString(),}))
    dispatch(invoicingActions.resetInvoice({invoiceBookDate: new Date().toISOString(),}))
    dispatch(comunicationInfoActions.resetComunicationCreateEmail({comunicationEmailDate: new Date().toISOString(),}))
    

    

    if(value===null){
      //reset information on order-slice for orderSelected and totalPaidOnOrderSelected------
      dispatch(orderActions.resetSelectedOrderInfo())
      //dispatch(paymentInActions.resetPaymentIn({paymentDate: new Date().toISOString(),})) // changed on 16.Feb.24 -- code for todays date as default
      dispatch(paymentInActions.resetPaymentIn({
        //paymentDate: new Date().toISOString(), // changed on 16.Feb.24 -- code for todays date as default
        paymentDate: new Date(currentPaymentInfo.paymentDate).toISOString(), // changed on 16.Feb.24 -- code for todays date as default
        paymentDateUpdated: new Date().toISOString(),
      }))
      dispatch(comunicationInfoActions.resetComunicationCreateEmail({comunicationEmailDate: new Date().toISOString(),}))
      //dispatch(invoicingActions.resetInvoice({invoiceBookDate: new Date().toISOString(),}))


      //dispatch(paymentPlanActions.resetPaymentPlan({paymentPlanSignedDate: new Date().toISOString(),}))
      
    }else{

      //console.log("this is the valuo on virtualize...",value)



      dispatch(orderActions.selectOrder({
        selectedOrderRef: value
      }))

      currentorderSelectedRefFinder(value)
      
    }


    dispatch(orderActions.showOrder())


  }

  //console.log("selected order from reducer...",selectedOrder)
  
  return (
    <Autocomplete
      id="virtualize-demo"
      sx={{ width: 200 }}
      disableListWrap
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      options={OPTIONS_2}
      //groupBy={(option) => option[0].toUpperCase()}
      renderInput={(params) => <TextField {...params} label="Order Ref" />}
      renderOption={(props, option) => [props, option]}
      renderGroup={(params) => params}
      onChange={(event, value) =>
        handleChange(event, value)
      }
     
    />
  );
}
//import axios from 'axios';
import { useState, useEffect, useContext, Fragment } from "react";

import axios from 'axios';

//loader Imports--------------
import { SyncLoader } from "react-spinners";

import { SocketContext } from "../../utils/socket-client";
import { useDispatch, useSelector } from "react-redux";



import InvoicingBaseDialog from './InvoicingBaseDialog';
import InvoiceBaseActions from './InvoiceBaseActions';

import { variables } from "../../Variables";

import {
  updateMessagesList,
  updateNotificationsTypeList,
} from "../../store/notifications-slice";

import { paymentInActions } from "../../store/payment-slice";
//import { paymentPlanActions } from "../../store/paymentPlan-slice";
import { invoicingActions } from "../../store/invoicing-slice";


//MUI IMPORTS--------------------------------------

import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Draggable from "react-draggable";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Button from "@mui/material/Button";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const InvoicingBase = (props) => {
  //SOCKET Notifications Variables--------------------------------------------------------------
  const dispatch = useDispatch();
  //const userInfo = useSelector((state) => state.user);
  const currentOrderSelected = useSelector(
    (state) => state.orders.selectedOrder
  );
//   const currentPaymentPlanInfo = useSelector((state) => state.paymentPlan);
  const messageListInitial = useSelector(
    (state) => state.notifications.messages
  );
  const currentInvoiceInfo = useSelector((state) => state.invoicing);
  const notificationsListInitial = useSelector(
    (state) => state.notifications.notificationsType
  );

  const currentPaymentInfo = useSelector((state) => state.paymentIn.paymentInfo);

  const selectedOrder = useSelector((state) => state.orders.selectedOrder);



  const [messagesList, setMessagesList] = useState(messageListInitial);
  const [notificationsList, setNotificationsList] = useState(
    notificationsListInitial
  );


  const [usersTitleList, setUsersTitleList] = useState([]);

  const [socket, setSocket] = useState(null);
  const mySocket = useContext(SocketContext);

  const {
    paymentTypeList,
    isNewInvoice,
    newInvoice,
    isLoading_BookInvoiceHandler,
    isLoading_BookInvoice,
  } = props;

  const [showPplanSigned, setShowPplanSigned] = useState(false);
  const [showPplanSet, setShowPplanSet] = useState(false);
  //const [showPplanSignedDetails, setShowPplanSignedDetails] = useState(false);
  const [showInvoiceDetails, setShowInvoiceDetails] = useState(false);
  
  const [showCreateNewInvoice, setShowCreateNewInvoice] = useState(false);

  const[invoiceNumber, setInvoiceNumber]=useState(null)




    // Handle Download PDF invoice----------------------------------------------------------------------------
    const handleDownload = async(invNumber) => {
      let invNumberFinal =""
      let invNetFinal=""
      let invVATFinal=""
      let invTotalFinal=""


      if(invNumber===3){
        invNumberFinal=""
        invNetFinal=selectedOrder[0].NET_AMOUNT
        invVATFinal=selectedOrder[0].VAT_AMOUNT
        invTotalFinal=selectedOrder[0].TOTAL_AMOUNT

      }else{
        invNumberFinal=" - "+invNumber
        invNetFinal=selectedOrder[0].NET_AMOUNT/2
        invVATFinal=selectedOrder[0].VAT_AMOUNT/2
        invTotalFinal=selectedOrder[0].TOTAL_AMOUNT/2
      }

      // Create a payload with the form data
      const invoiceData = {
          name: selectedOrder[0].MAIN_CONTACT,
          company: selectedOrder[0].COMPANY_NAME,
          email: selectedOrder[0].ClientEmail,
          confo:selectedOrder[0].CLUB_CODE+"-"+selectedOrder[0].CONFIRMATION_REFERENCE+ invNumberFinal,
          address1:selectedOrder[0].ADDRESS1 ,
          address2:selectedOrder[0].ADDRESS2 ,
          town:selectedOrder[0].TOWN,
          post:selectedOrder[0].ClientPost,
          project:selectedOrder[0].CLUB_NAME, //+" "+selectedOrder[0].YEAR,
          projectYear: selectedOrder[0].YEAR,
          net:invNetFinal,
          vat:invVATFinal,
          total:invTotalFinal,
          invoiceDate:new Date(selectedOrder[0].DATE).toLocaleString("default", {
            day: "numeric",
            month: "short",
            year:"numeric",
          }),
          description:"Inv.content",
          bankDetails_1:"LUDIS MARKETING CONSULTING LTD",
          bankDetails_2:"SortCode: 04-29-09 -- Acc: 49153552",
          companyDetails:"Company No. 14918692 Reg. Office: 38 Church Street,  3rd Floor,  Seaford,  East Sussex,  United Kingdom,  BN25 1LD  VAT No. 443 3170 21",
          invNumber:invNumber
  
      };
  
  
      try {
  
          const response = await axios.post(variables.API_URL + "comunication/createpdfinvoice", invoiceData,
              {
                  responseType: 'blob',  // Important: Ensures the response is handled as a binary file
              }
          )
  
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", selectedOrder[0].CLUB_CODE+"-"+selectedOrder[0].CONFIRMATION_REFERENCE+ invNumberFinal+".pdf");
          document.body.appendChild(link);
          link.click();
  
          console.log("downloading...")
  
      } catch (error) {console.error('Error generating PDF.. this is a Front end message:', error);}
  
    };
    // END Handle Download PDF invoice-------------------------------------------------------------------------





  //Show the modal with form to Create / Book a New Invoice on Order selected-----------
  const showBookInvoiceModalHandler = (event, action, inv) => {
    if (event) {
      event.preventDefault();
    }
    if (action === 1) {
      //console.log("this is the variable inv...",inv)
      setShowCreateNewInvoice((prev) => !showCreateNewInvoice);
      setInvoiceNumber(inv)
    } else if (action === 2) {
      setShowPplanSet((prev) => !showPplanSet);
    } else if (action === 3) {
      setShowPplanSigned((prev) => !showPplanSigned);
    } else {
      //setShowPplanSignedDetails((prev) => !showPplanSignedDetails);
      setShowInvoiceDetails((prev) => !showInvoiceDetails);
      setInvoiceNumber(inv)
    }
  };

  //CODE FOR THE SOCKET-------------------------------

  useEffect(() => {
    //setSocket(io("http://localhost:5002"));
    setSocket(mySocket);
    //console.log("connecting...");
  }, []);

  //listening to messages
  useEffect(() => {
    socket?.on("getText", (data) => {
      setMessagesList((prev) => [...prev, data.text]);

    });
  }, [socket]);

  //listening to notifications
  useEffect(() => {
    socket?.on("getNotification", (data) => {
      setNotificationsList((prev) => [...prev, data]);
      //console.log("receiving a notification object for PAYMENTS INNNNNNNNNNNNNNN", data);
      //console.log("receiving a notification", data.type);
    });
  }, [socket]);

  //getting all notifications from the socket to update the redux state------
  useEffect(() => {
    dispatch(
      updateMessagesList({
        messagesList,
      })
    );
  }, [messagesList]);

  //getting all notifications from the socket to update the redux state------
  useEffect(() => {
    dispatch(
      updateNotificationsTypeList({
        notificationsList,
      })
    );
  }, [notificationsList]);

  //CODE FOR THE SOCKET END---------------------------

  //Reseting the slice for paymentsIn redux
  useEffect(() => {
    //Reset The Payment In Slice to initial values function to be used after payment is recorded -----
    dispatch(
      paymentInActions.resetPaymentIn({
        paymentDate: new Date().toISOString(), // changed on 16.Feb.24 -- code for todays date as default
        //paymentDate: new Date(currentPaymentInfo.paymentDate).toISOString(), // changed on 16.Feb.24 -- code for todays date as default
        paymentDateUpdated: new Date().toISOString(),

      })
    );
    //END Reset The Payment In Slice to initial values function to be used after payment is recorded -----

    //Reset The Payment Plan Slice to initial values function to be used after payment is recorded -----
    dispatch(
      invoicingActions.resetInvoice({
        invoiceBookDate: new Date().toISOString(),
      })
    );
    //END Reset The Payment In Slice to initial values function to be used after payment is recorded -----
  }, []);



  return (
    <Fragment>
      <div>
        {/* <Divider /> */}

        {!isLoading_BookInvoice ? (
          <Fragment>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "auto",
              }}
            >
              {currentInvoiceInfo.invoicesOnOrder.invoice3.length === 0 && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection:
                      currentInvoiceInfo.invoicesOnOrder.invoice1.length ===
                        0 &&
                      currentInvoiceInfo.invoicesOnOrder.invoice2.length === 0
                        ? "row"
                        : "column",
                    alignItems: "center",
                    margin: "auto",
                  }}
                >
                  <InvoiceBaseActions
                    showBookInvoiceModalHandler={showBookInvoiceModalHandler}
                    //invoiceNumber = {invoiceNumber}
                    invoiceDesignator={1}
                    isInvoice={
                      currentInvoiceInfo.invoicesOnOrder.invoice1.length
                    }
                  />

                  {/* Division when there is no Invoice Recorded yet----------------------------------------------------------------------------  */}
                  {currentInvoiceInfo.invoicesOnOrder.invoice1.length === 0 &&
                    currentInvoiceInfo.invoicesOnOrder.invoice2.length ===
                      0 && (
                      <DragIndicatorIcon
                        sx={{
                          fontSize: 15,
                          //color: !selectedOrder.length ? "#ccc" : "#4E1721",
                          marginRight: 0,
                          marginLeft: 0,
                          marginTop: 2,
                        }}
                      />
                    )}

                  {/* <Divider></Divider> */}

                  {/* {currentInvoiceInfo.invoicesOnOrder.invoice3.length >0 && (
                    <DragIndicatorIcon
                      sx={{
                        fontSize: 15,
                        //color: !selectedOrder.length ? "#ccc" : "#4E1721",
                        marginRight: 0,
                        marginLeft: 0,
                        marginTop: 1,
                      }}
                    />
                  )} */}

                  <InvoiceBaseActions
                    showBookInvoiceModalHandler={showBookInvoiceModalHandler}
                    //invoiceNumber = {invoiceNumber}
                    invoiceDesignator={2}
                    isInvoice={
                      currentInvoiceInfo.invoicesOnOrder.invoice2.length
                    }
                  />
                </Box>
              )}

              {/* <Divider></Divider> */}

              {currentInvoiceInfo.invoicesOnOrder.invoice2.length === 0 &&
                currentInvoiceInfo.invoicesOnOrder.invoice1.length === 0 && (
                  <InvoiceBaseActions
                    showBookInvoiceModalHandler={showBookInvoiceModalHandler}
                    //invoiceNumber = {invoiceNumber}
                    invoiceDesignator={3}
                    isInvoice={
                      currentInvoiceInfo.invoicesOnOrder.invoice3.length
                    }
                  />
                )}

              {currentInvoiceInfo.invoicesOnOrder.invoice3.length > 0 &&
                currentInvoiceInfo.invoicesOnOrder.invoice2.length > 0 &&
                currentInvoiceInfo.invoicesOnOrder.invoice1.length > 0 && (
                  <PriorityHighIcon
                    sx={{
                      fontSize: 20,
                      color: !selectedOrder.length ? "#ccc" : "#4E1721",
                      marginRight: 0,
                      marginLeft: 0,
                      marginTop: 5,
                    }}
                  />
                )}
            </Box>
          </Fragment>
        ) : (
          <Box textAlign="center" sx={{ marginTop: 5 }}>
            <SyncLoader color="#7A1125" size={5} />
          </Box>
        )}

        <InvoicingBaseDialog
          showCreateNewInvoice={showCreateNewInvoice}
          showBookInvoiceModalHandler={showBookInvoiceModalHandler}
          socket={socket}
          paymentTypeList={paymentTypeList}
          isNewInvoice={isNewInvoice}
          newInvoice={newInvoice}
          isLoading_BookInvoiceHandler={isLoading_BookInvoiceHandler}
          isLoading_BookInvoice={isLoading_BookInvoice}
          invoiceNumber={invoiceNumber}
          usersTitleList={usersTitleList} //to be reviewd and find out if it is really need it...
        />

        {/* Modal for case: Show payment plan signed Details ---------------------- */}
        <Dialog
          //open={showPplanSignedDetails}
          open={showInvoiceDetails}
          //open={open}
          //onClose={handleClose}
          onClose={(event) => showBookInvoiceModalHandler(event, 4)}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          
        >
          <DialogTitle sx={{ color: "#4E1721", textTransform: "capitalize", margin:"auto" }}>
            Invoice Details
          </DialogTitle>
          <DialogTitle sx={{ color: "#7A1125", textTransform: "capitalize",margin:"auto" }}>
            Inv. {currentOrderSelected[0]?.CONFIRMATION_REFERENCE} -- {invoiceNumber===3? "Full" : invoiceNumber}
          </DialogTitle>
          <DialogContent>
            {currentOrderSelected.length > 0 && (
              <Fragment>
                <DialogContentText sx={{ margin: 2 }}>
                  On Order...{currentOrderSelected[0].CONFIRMATION_REFERENCE} --
                  Client: {currentOrderSelected[0].COMPANY_NAME}
                </DialogContentText>

                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    //showComunicationModalHandler(event, 1);
                    handleDownload(invoiceNumber);
                  }}
                  sx={{ color: "#7A1125", textTransform: "capitalize" }}
                >
                  <SimCardDownloadIcon
                    sx={{
                      fontSize: 20,
                      //color: !selectedOrder.length ? "#ccc" : "#4E1721",
                      //color: !invoiceRecorded ? "#ccc" : "#4E1721",
                      marginRight: 2,
                      marginTop: 2,
                    }}
                  />
                  <Typography
                    align="center"
                    //variant="body2"
                    //color="text.secondary"
                    sx={{ marginTop: 2, fontSize: 12, }}
                  >
                    {`Download Invoice ${invoiceNumber===3? "full":invoiceNumber}`}
                  </Typography>
                </Button>

                {/* <PaymentPlanInfo currentPaymentPlanInfo={currentPaymentPlanInfo}/> */}
              </Fragment>
            )}
            {/* <AddPplanSignedSteps steps={steps_signed} /> */}
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </div>
    </Fragment>
  );
};

export default InvoicingBase;

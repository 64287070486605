import { Fragment } from "react";

import { useDispatch, useSelector } from "react-redux";
import theme from "../UI/Theme";

//MUI IMPORTS--------------------------------------
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import Typography from "@mui/material/Typography";

const InvoiceBaseActions = (props) => {

    const currentInvoiceInfo = useSelector((state) => state.invoicing);
    const selectedOrder = useSelector((state) => state.orders.selectedOrder);
    const currentOrderSelected = useSelector((state) => state.orders.selectedOrder);

    const {showBookInvoiceModalHandler, invoiceDesignator, isInvoice } = props

    // const invoice = "invoice" + invoiceDesignator

    // console.log("this is my invoice...",invoice)
    // console.log("this is my invoice object full...",currentInvoiceInfo.invoicesOnOrder)
    // console.log("this is my invoice object...",currentInvoiceInfo.invoicesOnOrder.invoice1)



  return (
    <Fragment>
      <Button
        onClick={(event) => {
          event.preventDefault();
          {
            //currentInvoiceInfo.invoicesOnOrder_1[0].PRECONFIRMATION_ID
            //currentInvoiceInfo.invoicesOnOrder.invoice1.length > 0
            isInvoice > 0
              ? showBookInvoiceModalHandler(event, 4, invoiceDesignator)
              : showBookInvoiceModalHandler(event, 1, invoiceDesignator);

            //   : currentPaymentPlanInfo.payPlanInfo.PaymentPlanId
            //   ? showAddPaymentModalHandler(event, 3)
            //   : showAddPaymentModalHandler(event, 2);
          }
        }}
        sx={{
          color: "#7A1125",
          textTransform: "capitalize",
          margin: "auto",
          width: "100%",
        }}
        disabled={!selectedOrder.length ? true : false}
      >
        {/* {currentPaymentPlanInfo.payPlanInfo.selectedOrder.length > 0 ? "si" : "no"} */}
        {/* {currentInvoiceInfo.invoicesOnOrder_1[0].PRECONFIRMATION_ID ? ( */}
        {/* {currentInvoiceInfo.invoicesOnOrder.invoice1.length > 0 ? ( */}
        {isInvoice > 0 ? (

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin: invoiceDesignator === 3 ? 2.5:"auto",
            }}
          >
            {/* <CreateIcon
                  sx={{
                    fontSize: 20,
                    //color: !selectedOrder.length ? "#ccc" : "#4E1721",
                    color: !selectedOrder.length
                      ? "#ccc"
                      : theme.palette.common.arcBlue,
                    marginRight: 2,
                    //marginTop: 2,
                  }}
                /> */}

            {currentOrderSelected.length > 0 && 

            <Typography
              align="center"
              variant="body2"
              //color="text.secondary"
              sx={{ marginTop: 2, fontSize: 12 }}
              >
              {/* {currentOrderSelected[0].CLUB_CODE + " " + currentOrderSelected[0].CONFIRMATION_REFERENCE+"-1"} */}
              {invoiceDesignator === 3 ? "Inv. " + currentOrderSelected[0].CONFIRMATION_REFERENCE : 
              "Inv. " + currentOrderSelected[0].CONFIRMATION_REFERENCE +  "-" +invoiceDesignator}
            </Typography>
            }

            {/* <Avatar
                  alt="user pic"
                  src="https://ik.imagekit.io/bwcdq46tkc8/LudisGroup/GC-logo_MKBls7sH9n.png?ik-sdk-version=javascript-1.4.3&updatedAt=1653995686593"
                /> */}
            <CheckCircleOutlineIcon
              sx={{
                fontSize: 20,
                //color: !selectedOrder.length ? "#ccc" : "#4E1721",
                color: !selectedOrder.length
                  ? "#ccc"
                  : theme.palette.common.arcBlue,
                marginLeft: 2,
                marginTop: 2,
              }}
            />
          </Box>
        ) : (
          <>
            <NoteAddIcon
              sx={{
                fontSize: 12,
                color: !selectedOrder.length ? "#ccc" : "#4E1721",
                marginRight: 0.5,
                marginTop: 2,
              }}
            />
            <Typography
              align="center"
              //variant="body2"
              //color="text.secondary"
              sx={{ marginTop: 2, fontSize: 12 }}
            >
              Inv.{invoiceDesignator===3 ? "Full":invoiceDesignator }
            </Typography>
          </>
        )}
      </Button>
    </Fragment>
  );
};

export default InvoiceBaseActions;
